<template>
  <div>
    <b-row>
      <b-col sm="12" class="" :style="`height: ${titleHeight ? titleHeight : '8rem'} ;`">
        <slot></slot>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="9" class="mb-3">
        <forecast-income-chart 
          :chartData="activeYearData" 
          :unitsTitle="unitsTitle"
          :selectedYear="activeBPlan.startYear + activeYear"
          :readonly="readonly"
          :defaultMax="defaultMax"
          @data='updateData'>
        </forecast-income-chart>
        <hr class="border-300 my-2">
        <div class="chart-values-section d-flex align-items-top flex-row">   
          <div 
            v-for="(item, index) in activeYearData"
            :key="index"
            class="chart-cell col-1 text-center">
            <div class="chart-cell__value px-0 fs--2"
              :class="{
                'd-none': monthInputEdit == `monthInputIndex${index}`,
                'text-500': !item, 'text-800': item
              }"
              @click="startMonthInputEdit(index)">
              {{item | currency({fractionCount: 0, thousandsSeparator: "'"})}}
            </div>
            <b-form-input 
              class="chart-cell__input fs--1 px-0"
              :class="{'d-none': monthInputEdit != `monthInputIndex${index}`}"
              v-model="activeYearData[index]"
              ref="monthInput"
              type="number"
              number 
              placeholder="–"
              :disabled="readonly"
              :formatter="format"
              lazy-formatter
              @blur="monthInputEdit = ''"
              @change="updateData({index: index, value: activeYearData[index]})"
              @keydown.enter="updateData({index: index, value: activeYearData[index]})"
              @keydown.tab.prevent="onTabEnter({index: index, value: activeYearData[index]})">
            </b-form-input>
            <span v-if="index < 11 && !readonly"
              @click="spreadData({index: index, value: activeYearData[index]})">
              <i class="fas fa-long-arrow-alt-right"></i>
            </span>
          </div>
        </div>
      </b-col>
      <b-col sm="3" class="px-0">
        <forecast-income-years-chart 
          :chartData="yearsDataSum" 
          :colors="yearsBarsColors" 
          :unitsTitle="unitsTitle"
          :defaultMax="defaultMax"
          @activeYear="activeYearSelect"/>
        <hr class="border-300 my-2">
        <div class="chart-values-section d-flex flex-row">
          <div class="chart-cell col-4 text-center"
            v-for="(year, index) in yearsDataSum"
            :key="index">
            <div v-if="index == 0" 
              class="chart-cell__value year-value px-0 fs--2"
              :class="{'active': activeYear == index, 'text-500': !year, 'text-900': year}"
              @click="activeYearSelect(0)">
              {{year | currency({fractionCount: 0, thousandsSeparator: "'"})}}
            </div>
            <div v-if="index > 0" 
              class="chart-cell__value px-0 fs--2"
              :class="{'d-none': activeYear == index, 'text-500': !year, 'text-900': year}"
              @click="activeYearSelect(index)">
              {{year | currency({fractionCount: 0, thousandsSeparator: "'"})}}
            </div>
            <b-form-input v-if="index > 0"
              ref="yearInput"
              class="chart-cell__input fs--1 px-0" 
              :class="{
                'd-none': activeYear != index || index == 0,
                'active': activeYear == index}"
              v-model="yearsDataSum[index]"
              type="number"
              number
              placeholder="-"
              :formatter="updateYearData"
              lazy-formatter           
              :disabled="index == 0 || readonly || yearsMedian"
              @keydown.enter="updateOnEnter">
            </b-form-input>
          </div>
        </div>
      </b-col>
    </b-row> 
  </div>
</template>

<script>
import ForecastIncomeChart from '../../../charts/ForecastIncomeChart'
import ForecastIncomeYearsChart from '../../../charts/ForecastIncomeYearsChart'

export default {
  props: ['data', 'yearsData', 'unitsTitle', 'readonly', 'decimals', 'defaultMax', 'yearsMedian', 'titleHeight'],
  data: () => ({
    activeYear: 0,
    monthInputEdit: '',
    yearsBarsColors: ['#2c7be5','#9da9bb','#9da9bb']
  }),
  components: {
    ForecastIncomeChart,
    ForecastIncomeYearsChart
  },
  computed: {
    activeBPlan() {
      return this.$store.getters.getActiveBPlan;
    },

    activeYearData() {
      return this.data[this.activeYear]   
    },

    yearsDataSum() {
      var yearsSum = []
      var yearsMedian = []
      var conutNotNull = null
      const arrSum = arr => arr.reduce((a,b) => a + b, 0)
      Object.values(this.data).forEach(itemData => {
        conutNotNull = null
        yearsSum.push(arrSum(itemData))
        
        // Находим количество элементов с реальными цифрами, для более точного вычисления средней
        itemData.forEach(item => {
          if (item > 0) {conutNotNull++}
        })
        // Проверяем, чтоб не получит деление на 0
        if (conutNotNull > 0) {
          yearsMedian.push((parseFloat((arrSum(itemData)/conutNotNull).toFixed(this.decimals))))
        } else {
          yearsMedian.push(0)
        }
      })
      if (this.yearsMedian) {
        return yearsMedian
      } else {
        return yearsSum
      }
    }
  },
  methods: {
    updateData(newData) {
      // this.monthInputEdit = ''
      this.$set(this.activeYearData, newData.index, this.format(newData.value))
      this.$emit('data', {year: this.activeYear, index: newData.index})
    },

    activeYearSelect(index) {
      var colors = ['#9da9bb','#9da9bb','#9da9bb'] 
      // Снимаем фокус с ввода данных текущего года, для фиксации внесенных данных
      if (this.activeYear != 0) {
        this.$refs.yearInput[this.activeYear-1].blur()
      }
      // this.$el.querySelector(`#yearDataInput${this.activeYear}`).blur()
      this.activeYear = index
      colors[index] = '#2c7be5'
      this.yearsBarsColors = colors

      if (index > 0) {
        setTimeout(() => {
          this.$refs.yearInput[index-1].focus()
        }, 100)
      }
      
    
      // this.$el.querySelector(`#yearDataInput${this.activeYear}`).focus()
    },

    format(value) {
      if (value <= 0) {
        return null
      } else {
        return parseFloat((Math.round(value * 100) / 100).toFixed(this.decimals))
      }
    },

    updateYearData(yearValue) {
      const arrSum = arr => arr.reduce((a,b) => a + b, 0)

      // if we have no changes in year summ we do nothing
      if (yearValue == arrSum(this.activeYearData)) {
        return yearValue
      } else {
        var monthValue = parseFloat((Math.round((yearValue / 12) * 100) / 100).toFixed(this.decimals))
        if (monthValue > 0) {
          for (var i=0; i < 12; i++) {this.$set(this.activeYearData, i, monthValue)}
          return monthValue * 12
        } else {
          return 0
        }        
      }
    },

    updateOnEnter(e) {
      this.updateYearData(e.target.value)
      
      if (this.activeYear != 0) {
        this.$refs.yearInput[this.activeYear-1].blur()
      }
      // this.$el.querySelector(`#yearDataInput${this.activeYear}`).blur()
    },

    onTabEnter(newData) {
      this.startMonthInputEdit(newData.index+1)
    },

    spreadData(payload) {
      for (var i = payload.index; i < 12; i++) {
        this.updateData({index: i, value: payload.value})
      }
    },

    startMonthInputEdit(index) {
      this.monthInputEdit = `monthInputIndex${index}`
      setTimeout(() => {
         this.$refs.monthInput[index].focus()
      }, 100)
     
    }
  }

}
</script>

<style lang="scss" scoped>
.highcharts-plot-background {
	fill: #3a3939c0;
}

.delete-btn {
  color: rgba(145, 145, 145, 0.2);
  border: none;
  span {
    display: none;
  }
  &:hover {
    color: #e63757;
    border-color: rgb(145, 145, 145) !important;
    span {
      display: inline;
    }
  }
}

.chart-cell {
  span {
    position: absolute; 
    bottom: -2.1rem; 
    left: 41%;
    display: none;
    color: #637081;
  }
  &:hover {
    span {
      display: inline;
    }
  }
}
   
.chart-cell__value,
.chart-cell__input {
    outline: none;
    border-radius: 3px;
    border: 1px solid transparent;
    background: transparent;
    height: 26px;
    display: inline-block;
    vertical-align: middle;
    width: 76px;
    font-family: 'Proxima Nova';
    text-overflow: ellipsis;
    padding: 0 10px;
    position: absolute;
    left: 50%;
    margin-left: -38px;
    top: -7px;
    line-height: 26px;
    text-align: center;
    z-index: 2;
    border-radius: 5px;
    &.fs--2 {
      font-size: 0.75rem;
    }
    &.year-value {
      width: 5rem;
    }
    &.active {
      border-radius: 2px;
      font-size: 0.85rem;
      font-weight: 600;
      // background: #2c7ce55d;
    }
}



input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
</style>