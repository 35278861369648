<template>
  <div style="position: relative; height: 200px; overflow: hidden;">
    <highcharts :options="chartOptions2"></highcharts>
  </div>
</template>

<script>
import numeral from 'numeral';
import moment from 'moment';

export default {
  props: ['chartData', 'periodType', 'bgColor'],
  data: () => ({
  }),
  computed: {
    xAxisLabels() {
      var labels = [];
      for (var i = 0; i < 12; i++) {
        labels.push(moment().month(i).format('MMM'));
      }
      return labels;
    },

    chartOptions2() {
      return {
        chart: {
          type: 'spline',
          height: 200,
          marginLeft: 1,
          marginRight: 1,
          backgroundColor: this.bgColor
        },
        credits: false,
        title: {
          text: null
        },
        legend: false,
        tooltip: {
          crosshairs: true,
          shared: true,
          formatter: function () {
            const index = this.points[0].point.x
            const prevPointData = this.points[0].series.yData[index - 1]
            const pointData = this.points[0].series.yData[index]
            var change = prevPointData ? (pointData / prevPointData - 1) : 0
            
            return '<b>' + numeral(this.y).format('(0.0a)') + '</b><br>' + 'изм.: <b>' + numeral(change).format('0%') + '</b>'
          }
        },
        yAxis: {
          min: 0,
          softMax: 1000,
          border: 1,
          minorTickInterval: 'auto',
          showFirstLabel: false,
          minorGridLineWidth: 1,
          gridLineWidth: 1,
          gridLineColor: '#3a39392a',
          minorGridLineColor: '#3a39391a',
          labels: {
            align: 'left',
            enabled: true,
            x: 0,
            y: 15,
            style: {
              color: '#ffffff8a',
            },
          },
          title: {
            text: 'Выручка',
          },
          plotLines: [{
            color: '#3a3939e4',
            width: 1,
            value: 0,
            zIndex: 1
          }]
        },
        xAxis: {
          categories: this.xAxisLabels,
          labels: {
            style: {
              color: '#D3D3D3',
            },
          }
        },
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
            },
            enableMouseTracking: false
          },
          series: {
            dataLabels: {
              enabled: false,
              //!TODO: Show % changes in chart labels
              // formatter: function () {
              //   const index = this.point.x
              //   console.log(this.point)
              //   return index
              // }
            },
            marker: {
              fillColor: '#D3D3D3',
              lineWidth: 2,
              radius: 3,
              lineColor: null,
              symbol: 'circle', // inherit from series
            },
            fillOpacity: 0.9
          }
        },
        series: [{
          data: this.chartData,
          color: 'white',
          fillColor: '#adcdf7b3',
        }]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.highcharts-plot-background {
	fill: #3a3939c0;
}
</style>

