<template>
  <div style="position: relative; height: 200px; overflow: hidden;">
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import numeral from 'numeral'

export default {
  props: ['bgColor', 'chartData'],
  computed: {
    activeBPlanStartYear() {
      return this.$store.getters.getActiveBPlan.startYear;
    },

    chartOptions() {
      return {
        chart: {
          type: 'column',
          height: 200,
          marginLeft: 1,
          marginRight: 1,
          backgroundColor: this.bgColor
        },
        credits: false,
        title: {
          text: null
        },
        legend: false,
        tooltip: {
          crosshairs: true,
          shared: true,
          formatter: function () {
            return '<b>' + numeral(this.y).format('(0.00a)') + '</b>';
          }
        },
        plotOptions: {
          column: {
            borderColor: 'lightgray'
          },
        },
        yAxis: {
          min: 0,
          softMax: 1000,
          border: 1,
          minorTickInterval: 'auto',
          minorGridLineWidth: 1,
          gridLineWidth: 1,
          gridLineColor: '#3a39392a',
          minorGridLineColor: '#3a39392a',
          labels: {
            align: 'left',
            enabled: true,
            x: 0,
            y: 15,
            style: {
              color: '#ffffff8a',
            },
          },
          title: {
            text: 'Выручка',
          },
        },
        xAxis: {
          categories: [
            `${this.activeBPlanStartYear} год`,
            `${this.activeBPlanStartYear + 1} год`,
            `${this.activeBPlanStartYear + 2} год`,
          ],
          labels: {
            style: {
              color: '#D3D3D3',
            },
          }
        },
        series: [{
          data: this.chartData,
          color: 'lightgray',
          states: {
            hover: {
              color: 'grey',
              borderColor: 'gray'
            }
          },
        }]
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

