<template>
  <div style="height: 250px; z-index: 2;">
    <highcharts :options="chartOptions2"></highcharts>
    <span class="units-title">{{unitsTitle}}</span>
    <span class="selected-year font-proxima">{{selectedYear}} <small>год</small></span>
  </div>
</template>

<script>
import draggablePoints from 'highcharts/modules/draggable-points'
import Highcharts from 'highcharts'

draggablePoints(Highcharts)

export default {
  props: ['chartData', 'bgColor', 'unitsTitle', 'selectedYear', 'readonly', 'defaultMax'],
  data: () => ({
  }),
  computed: {
    newChartData() {
      return this.chartData
    },
    yearSum() {
      const arrSum = arr => arr.reduce((a,b) => a + b, 0)
      return arrSum(this.newChartData)
    },
    chartOptions2() {
      return {
        chart: {
          type: 'spline',
          height: 250,
          marginLeft: 1,
          marginRight: 1,
          backgroundColor: this.bgColor,
          events: {
            click: () => {
              if (!this.readonly) {
                this.pointClick(
                  {
                    index: Math.round(event.xAxis[0].value),
                    value: Math.ceil(event.yAxis[0].value)
                  }
                )
              }
            },
          }
        },
        credits: false,
        title: {
          text: null
        },
        legend: false,
        tooltip: false,
        yAxis: {
          min: 0,
          max: (this.yearSum == 0) ? this.defaultMax : null,
          showEmpty: true,
          tickPixelInterval: 50,
          showFirstLabel: false,
          border: 1,
          minorTickInterval: 'auto',
          minorGridLineWidth: 1,
          gridLineWidth: 1,
          gridLineColor: '#344050',
          minorGridLineColor: '#3440502a',
          labels: {
            align: 'left',
            enabled: true,
            x: 0,
            y: 15,
            style: {
            	color: '#919FB2',
            },
          },
          title: {
            text: 'Выручка',
          },
          plotLines: [{
            color: '#3a3939e4',
            width: 1,
            value: 0,
            zIndex: 1
          }]
        },
        xAxis: {
          categories: ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декбрь'],
          labels: {
            style: {
                color: '#9da9bb',
              }
          }
        },
        plotOptions: {
          line: {
            dataLabels: {
              enabled: false
            },
            enableMouseTracking: true
          },
          series: {
            stickyTracking: false,
            dragDrop: {
                draggableY: !this.readonly
            },
            dataLabels: {
              enabled: false,
            },
            marker: {
              fillColor: '#0e1c2f',
              lineWidth: 2,
              radius: 3,
              lineColor: null,
              symbol: 'circle', // inherit from series
            }
          }
        },
        series: [{
          data: this.newChartData,
           point: {
              events: {
                drag: (event) => {
                  if (!this.readonly) {
                    this.pointClick(
                      {
                        index: event.target.index,
                        value: Math.round(event.newPoint.y)
                      }
                    )
                  }
                }
              }
            },
          color: '#4C9BEE',
          fillColor: '#adcdf7b3',
        }]
      }
    }
  },
  methods: {
    pointClick(newData) {
      this.$emit('data', newData)
    } 
  }
}
</script>

<style lang="scss" scoped>
.units-title {
  position: absolute;
  top: -15px;
  font: small-caption;
}

.selected-year {
  position: absolute;
  // color: rgba(0, 0, 0, 0.2);
  z-index: 5;
  font-size: 1.2rem;
  font-weight: 600;
  top: -1.45rem;
  right: 1.1rem;
}

.highcharts-plot-background {
	fill: #3a3939c0;
}
</style>

