<template>
  <div style="height: 250px; overflow: hidden;">
    <highcharts :options="chartOptions"></highcharts>
    <span class="units-title">{{unitsTitle}}</span>
  </div>
</template>

<script>
import numeral from 'numeral'

export default {
  props: ['bgColor', 'chartData', 'colors', 'unitsTitle', 'defaultMax'],
  computed: {
    chartDataSum() {
      const arrSum = arr => arr.reduce((a,b) => a + b, 0)
      return arrSum(this.chartData)
    },
    
    activeBPlanStartYear() {
      return this.$store.getters.getActiveBPlan.startYear;
    },

    chartOptions() {
      return {
        chart: {
          type: 'column',
          height: 250,
          marginLeft: 1,
          marginRight: 0,
          paddngLeft: 2,
          backgroundColor: this.bgColor
        },
        credits: false,
        title: {
          text: null
        },
        legend: false,
        tooltip: {
          shared: true,
          distance: 10,
          formatter: function () {
            return '<b>' + numeral(this.y).format('(0.00a)') + '</b>';
          }
        },
        plotOptions: {
          column: {
            colorByPoint: true,
            colors: this.colors,
            borderColor: false
          },
        },
        yAxis: {
          min: 0,
          max: (this.chartDataSum == 0) ? this.defaultMax : null,
          showFirstLabel: false,
          border: 1,
          minorTickInterval: 'auto',
          minorGridLineWidth: 1,
          gridLineWidth: 1,
          gridLineColor: '#344050',
          minorGridLineColor: '#3440502a',
          labels: {
            align: 'left',
            enabled: true,
            x: 0,
            y: 15,
            style: {
              color: '#919FB2',
            },
          },
          title: {
            text: 'Выручка',
          },
        },
        xAxis: {
          categories: [
            `${this.activeBPlanStartYear} год`, 
            `${this.activeBPlanStartYear + 1} год`,
            `${this.activeBPlanStartYear + 2} год`,
          ],
          labels: {
            style: {
              color: '#9da9bb',
            },
          }
        },
        series: [{
          data: this.chartData,
          name: 'продажи',
          // color: 'lightgray',
          states: {
            hover: {
              color: 'grey',
              borderColor: 'gray'
            }
          },
          events: {
            click: (event) => {
              this.activeYearSelect(event.target.point.index)              
            }
          },
        }]
      }
    }
  },
  methods: {
    activeYearSelect(index) {
      this.$emit('activeYear', index)
    }
  }
}
</script>

<style lang="scss" scoped>
.units-title {
  position: absolute;
  top: -15px;
  font: small-caption;
}

.highcharts-plot-background {
	fill: #3a3939c0;
}
</style>

