<template>
  <div class="table-responsive">
    <table class="table table-sm mb-0 table-dashboard fs--0" style="font-family: 'Proxima Nova'">
    <thead class="bg-400 text-800">
      <tr>                   
        <th 
          class="pl-3" 
          style="min-width: 300px; vertical-align: middle;" 
          rowspan="2">
          Наименование
        </th>
        <th v-for="y in 3" :key="y"
          class="text-center py-0"
          style="min-width: 30px; border-left: 1px solid #06132550;"
          scope="colgroup"
          :rowspan="y == activeYear + 1 ? 1 : 2"
          :colspan="y == activeYear + 1 ? 5 : 1"
          @click="selectActiveYear(y - 1)">
          {{activeBPlan.startYear + (y - 1)}} <small>год</small>
          <div v-if="y != activeYear + 1" 
            class="my-1">
            <div class="btn btn-outline-dark btn-sm p-0"
              style="height: 20px; width: 20px; line-height: 15px;">
              +
            </div>
          </div> 
        </th>
      </tr>
      <tr>
        <!-- Отображение по кварталам -->
        <th v-for="i in 4" :key="i.key"
          class="text-center" 
          style="min-width: 50px;">
          {{i}} <small>кв.</small>
        </th>
        <th 
          class="text-right pr-2 fs--1 text-center" 
          style="width: 90px; line-height: 1; border-left: 1px solid #06132550;">
          {{selectedData == 'actualValue' ? "На конец года" : "Итого по году"}}
        </th>              
      </tr>
    </thead>
    <tbody style="font-size: 0.75rem;">
      <tr 
        v-for="(item, index) in tableData.data"
        :key="item.key"
        class="hover-shadow title">
        <td class="d-flex justify-content-between pl-2 py-0"
          style="max-width: 25rem;">
          <span class="fs--1" 
            style="white-space: nowrap; max-width: 25rem; overflow: hidden; text-overflow: ellipsis; line-height: 1.4rem;">
              <small class="mr-2 text-300">{{index + 1}}. </small>
              {{item.title}}
          </span>
          <div class="btn btn-outline-dark btn-sm edit-btn p-0 mr-2"
            @click="openToEdit(index)">
            <i class="fas fa-pencil-alt p-0"></i>
          </div>
        </td>
        <td 
          v-for="(value, index) in item.values"
          :key="index.key"
          class="text-right p-0 pr-1"
          style="vertical-align: bottom; min-width: 70px;"
          :class="{
            'font-weight-bold':index == activeYear + 4,
            'border-left-300':  index != 0,
            'border-left-400':  index == 0 || 
                                index == activeYear + 4 || 
                                index == activeYear + 5 || 
                                index == activeYear + 6 || 
                                index == activeYear + 7,
          }">
          {{ value | numeralFormat('(0.0a)') }}              
        </td>
      </tr>
    </tbody>
    <tfoot class="bg-300 text-800">
      <tr>
        <td class="pl-3 pr-2 py-0 text-right font-weight-semi-bold"
          style="height: 1.8rem;">
          <span class="mr-2 fs--1">
            ИТОГО:
          </span> 
        </td>
        <td class="text-right p-0 pr-1 fs--2 align-bottom"
          v-for="(value, index) in tableData.total"
          :key="index.key"
          style="vertical-align: bottom; min-width: 70px;"
          :class="{
            'font-weight-bold fs--1':index == activeYear + 4,
            'border-left-300':  index != 0,
            'border-left-400':  index == 0 || 
                                index == activeYear + 4 || 
                                index == activeYear + 5 || 
                                index == activeYear + 6 || 
                                index == activeYear + 7,
          }">
          <span class="fs--1">
            {{ value | numeralFormat('(0.0a)') }}
          </span>
        </td>
      </tr>
    </tfoot>
  </table>
</div>  
</template>

<script>

export default {
  props: {
    tableData: {
      type: Object,
      default: function() {
        return []
      } 
    },
    selectedData: { // Селектор данных между "Платежами" (payments) и "Остаточной стоимостью" (actualValue)
      type: String,
      default: 'actualValue'  
    },
  },
  data: () => ({
    activeYear: 0,
    
  }),
  mounted() {

  },
  computed: {
    activeBPlan() {
      return this.$store.getters.getActiveBPlan ? this.$store.getters.getActiveBPlan : {}
    },
  },

  methods: {
    // Устанавливаем активный год для детализации
    selectActiveYear(year) {
      this.$emit('activeYearSelect', year);
      this.activeYear = year;
    },
    
    openToEdit(index) {
      this.$emit('openToEdit', index);
    }
  }
}

</script>

<style lang="scss" scoped>

.border-left-400 {
  border-left: 1px solid #4d596961 !important;
}

.border-left-300 {
  border-left: 1px solid #2a344162 !important;
}

tfoot {
  .border-left-400 {
    border-left: 1px solid #06132550 !important;
  }
}

thead, tfoot {
  font-family: 'Proxima Nova';
  background: #4d596961 !important;
}

.fs-total {
  height: 1.5rem;
  padding-left: 5px !important;
  background: #34405055;
  font-weight: 600;
  font-size: 0.8rem;
  vertical-align: middle;
  &.result {
    background: #344050;
  }
}

.title {
  height: 20px;
  &:hover {
    .edit-btn {
      display: inline;
    }
  }
  .edit-btn {
    color: rgba(145, 145, 145, 0.2);
    display: none;
    border: none !important;
    &:hover {
      color: rgb(145, 145, 145);
    }
  }
}

.categoryTotal {
  background: #34405085;
  font-weight: 600;
  vertical-align: bottom;
  font-size: 0.75rem;
}

.sub-category-total-result {
  background: #344050;
  border-top: 1px solid #2a3441;
}

.item-total {
  background: #34405055;
  &.result {
    background: #344050;
  }
}

.sub-item {
  background: #34405050
}

.negative-value {
  color: #3b0b09;
}


</style>